import { RouteRecordRaw, createRouter, createWebHistory } from "vue-router";
import { authorize } from "@/plugins/can";
import { AppRouteName } from "@/router/RouteName";
import { handleError } from "@/services/shared/handleError";
import { useAppStore } from "@/stores/app";
import { useAuthStore } from "@/stores/auth";
import { Role } from "@/types/_generated/api";
import { adminWebRoutes } from "./modules/adminWebRoutes";
import { appRoutes } from "./modules/appRoutes";
import { recRespRoutes } from "./modules/recRespRoutes";
import { userWebRoutes } from "./modules/userWebRoutes";

const routes: Array<RouteRecordRaw> = [
  ...appRoutes,
  ...adminWebRoutes,
  ...userWebRoutes,
  ...recRespRoutes,
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(_to, _from, _savedPosition) {
    // Manually set scrollTop for layouts since the router only handles window scroll
    const userWebLayout = document.getElementById("user-web-view-layout");
    if (userWebLayout) userWebLayout.scrollTop = 0;
  },
});

router.beforeEach(async (to, _from, next): Promise<void> => {
  // Public pages are allowed
  if (to.meta.isPublic === true) {
    next();
    return;
  }

  const auth = useAuthStore();

  // Update session if necessary
  if (!auth.isAuthenticated) {
    try {
      await auth.hasSession();
    } catch (e) {
      handleError(e);
    }
  }

  // Go to auth if user is not authenticated
  if (!auth.isAuthenticated) {
    next({ name: AppRouteName.Auth });
    return;
  }

  const app = useAppStore();

  // Go to auth if user is not loaded
  if (!app.user) {
    next({ name: AppRouteName.Auth });
    return;
  }

  // Check if user has accepted terms of service
  if (!app.user.hasAcceptedTermsOfService) {
    next({ name: AppRouteName.TermsOfService });
    return;
  }

  // Check if user is allowed to access user web
  if (app.user.role === Role.EXT && !app.user.allowUserWeb && to.path.startsWith("/user-web/")) {
    next({ path: "/404" });
    return;
  }

  const isExtWithoutAdminAccess =
    app.user.role === Role.EXT &&
    to.path.startsWith("/admin/") &&
    to.params.moduleId &&
    !authorize.hasModulePermission(Number(to.params.moduleId), "editSurvey");

  if (isExtWithoutAdminAccess) {
    next({ path: "/404" });
  } else {
    next();
  }
});

export default router;
