const COOKIE_DOMAIN = "c344427a-81cd-4e7e-beff-881075868c0d-test";

export const addCookieBanner = () => {
  // Create and add the auto-block script
  const autoBlockScript = document.createElement("script");
  autoBlockScript.src = `https://cdn.cookielaw.org/consent/${COOKIE_DOMAIN}/OtAutoBlock.js`;
  autoBlockScript.type = "text/javascript";

  // Create and add the main cookie banner script
  const bannerScript = document.createElement("script");
  bannerScript.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
  bannerScript.type = "text/javascript";
  bannerScript.setAttribute("data-domain-script", COOKIE_DOMAIN);
  document.head.appendChild(bannerScript);

  // Add the wrapper function
  const wrapperScript = document.createElement("script");
  wrapperScript.type = "text/javascript";
  wrapperScript.textContent = "function OptanonWrapper() { }";
  document.head.appendChild(wrapperScript);
};
