<script setup lang="ts">
import { defineAsyncComponent, computed } from "vue";
import type { IconName } from "./types";

export interface Props {
  icon: IconName;
  size?: "xsmall" | "small" | "medium" | "large" | "xlarge" | "xxlarge";
}

const props = defineProps<Props>();

const iconComponent = computed(() => {
  return defineAsyncComponent({
    loader: () =>
      import(
        `@/assets/icons/Icon${props.icon
          .split("-")
          .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
          .join("")}.vue`
      ),
    // Handle errors
    onError(error, retry, _fail, attempts) {
      if (attempts <= 3) {
        // Retry up to 3 times
        retry();
      } else {
        // eslint-disable-next-line no-console
        console.error(`Failed to load icon: ${props.icon}`, error);
      }
    },
  });
});

const classes = computed(() => {
  return ["base-icon", `base-icon--${props.size ?? "small"}`];
});
</script>

<template>
  <component :is="iconComponent" :class="classes" />
</template>

<style scoped lang="scss">
.base-icon {
  flex-shrink: 0;

  &--xsmall {
    width: 0.5rem;
    height: 0.5rem;
  }

  &--small {
    width: 0.75rem;
    height: 0.75rem;
  }

  &--medium {
    width: 1rem;
    height: 1rem;
  }

  &--large {
    width: 1.5rem;
    height: 1.5rem;
  }

  &--xlarge {
    width: 1.75rem;
    height: 1.75rem;
  }

  &--xxlarge {
    width: 3rem;
    height: 3rem;
  }
}
</style>
