<script setup lang="ts">
import { ref } from "vue";
import { useAppStore } from "@/stores/app";
import BaseIcon from "../base/BaseIcon/BaseIcon.vue";
import BaseOption from "../base/BaseSelect/BaseOption.vue";
import BaseSelect from "../base/BaseSelect/BaseSelect.vue";

const app = useAppStore();
const show = ref(false);

// toggle console
window.addEventListener("keydown", (e) => {
  if (e.key === "§" || e.key === "F2") {
    show.value = !show.value;
  }
});
</script>

<template>
  <transition name="fade" appear>
    <div v-if="show" class="app-console">
      <div class="app-console__language">
        <BaseIcon icon="languages" size="medium" />

        <BaseSelect :value="app.settings.locale" @change="app.setAppLocale">
          <BaseOption
            v-for="(locale, index) in app.supportedLocales"
            :key="`${index}_${locale.value}`"
            :value="locale.value"
          >
            {{ locale.title }}
          </BaseOption>
        </BaseSelect>
      </div>
    </div>
  </transition>
</template>

<style scoped lang="scss">
.app-console {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $spacing-4;
  background: $secondary-2;
  border-bottom: 1px solid $secondary-3;
  z-index: $z-10;

  &__language {
    display: flex;
    align-items: center;
    gap: $spacing-2;
    margin-top: $spacing-4;
  }
}
</style>
