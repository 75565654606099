<template>
  <p class="base-input-error">
    <slot></slot>
  </p>
</template>

<style scoped lang="scss">
.base-input-error {
  color: $error-4;
  line-height: $leading-tight;
  font-size: $text-xs;
  margin-top: $spacing-1;
}
</style>
