<script setup lang="ts">
import { useId } from "vue";
import { BlueColor } from "@/types/_generated/api";
import { OptionValue } from "@/types/Option";
import BaseInputDescription from "../BaseInput/BaseInputDescription.vue";
import BaseInputError from "../BaseInput/BaseInputError.vue";
import BaseInputLabel from "../BaseInput/BaseInputLabel.vue";

defineProps<{
  value?: OptionValue;
  label?: string;
  ariaLabel?: string;
  errors?: string[] | readonly string[];
  required?: boolean;
  disabled?: boolean;
  description?: string;
  blueColor?: BlueColor;
}>();

const emit = defineEmits<{
  change: [value: string];
}>();

const componentId = useId();

const handleSelectChange = (event: Event) => {
  const target = event.target as HTMLSelectElement;
  const newValue = target.value;
  emit("change", newValue);
};
</script>

<template>
  <div class="base-select__wrapper">
    <BaseInputLabel v-if="label" :required="required">
      {{ label }}
    </BaseInputLabel>

    <select
      :id="componentId"
      class="base-select"
      :class="{ [`base-select--${blueColor?.toLowerCase()}`]: blueColor }"
      :value="value"
      :aria-label="ariaLabel"
      :disabled="disabled"
      @change="handleSelectChange"
    >
      <slot></slot>
    </select>

    <BaseInputDescription v-if="description && !errors?.length" :description="description" />

    <div v-if="errors?.length && !disabled">
      <BaseInputError v-for="(error, index) in errors" :key="index">
        {{ error }}
      </BaseInputError>
    </div>
  </div>
</template>

<style scoped lang="scss">
.base-select {
  padding: $spacing-3 $spacing-10 $spacing-3 $spacing-3;
  border: 1px solid $primary-6;
  border-radius: $rounded-base;
  min-height: 2.7rem;

  /* Arrow */
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='20' height='20' fill='currentColor'%3E%3Cpath fill-rule='evenodd' d='M12.78 6.22a.75.75 0 010 1.06l-4.25 4.25a.75.75 0 01-1.06 0L3.22 7.28a.75.75 0 011.06-1.06L8 9.94l3.72-3.72a.75.75 0 011.06 0z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right $spacing-3 top 50%;
  background-size: 1rem auto;

  @include blue-rating-styles;

  &__wrapper {
    display: flex;
    flex-direction: column;
  }
}
</style>
