import type { RouteRecordRaw } from "vue-router";
import { UserWebRouteName } from "../RouteName";

export const userWebRoutes: RouteRecordRaw[] = [
  {
    path: "/user-web",
    name: UserWebRouteName.Root,
    redirect: { name: UserWebRouteName.Modules },
    component: () => import("@/views/UserWeb/ViewUserWeb.vue"),
    children: [
      {
        path: "modules",
        name: UserWebRouteName.Modules,
        component: () => import("@/views/UserWeb/Modules/ViewModules.vue"),
      },
      {
        path: "modules/:moduleId",
        name: UserWebRouteName.Module,
        redirect: { name: UserWebRouteName.Dashboard },
        children: [
          {
            path: "dashboard",
            name: UserWebRouteName.Dashboard,
            component: () => import("@/views/UserWeb/Dashboard/DashboardView.vue"),
            props: (route) => ({ moduleId: Number(route.params.moduleId) }),
          },
          {
            path: "sites",
            name: UserWebRouteName.Sites,
            component: () => import("@/views/UserWeb/Sites/ViewSites.vue"),
            props: (route) => ({ moduleId: Number(route.params.moduleId) }),
            children: [
              {
                path: ":siteId",
                redirect: { name: UserWebRouteName.SiteDetails },
                name: UserWebRouteName.Site,
                component: () => import("@/views/UserWeb/Sites/ViewSite.vue"),
                props: (route) => ({
                  moduleId: Number(route.params.moduleId),
                  siteId: Number(route.params.siteId),
                }),
                children: [
                  {
                    path: "details",
                    name: UserWebRouteName.SiteDetails,
                    component: () => Promise.resolve({ render: () => null }),
                  },
                  {
                    path: "rec-tracking",
                    name: UserWebRouteName.SiteRecTracking,
                    component: () => Promise.resolve({ render: () => null }),
                  },
                  {
                    path: "blue-rating",
                    name: UserWebRouteName.SiteBlueRating,
                    component: () => Promise.resolve({ render: () => null }),
                  },
                  {
                    path: "reports",
                    name: UserWebRouteName.SiteReports,
                    component: () => Promise.resolve({ render: () => null }),
                  },
                ],
              },
            ],
          },
          {
            path: "sites/:siteId/surveys/:surveyId/spoe-check",
            name: UserWebRouteName.SpoeCheck,
            component: () => import("@/views/UserWeb/Surveys/ViewSpoeCheck.vue"),
            props: (route) => ({
              moduleId: Number(route.params.moduleId),
              siteId: Number(route.params.siteId),
              surveyId: Number(route.params.surveyId),
            }),
          },
          {
            path: "sites/:siteId/surveys/:surveyId/online-report",
            name: UserWebRouteName.OnlineReport,
            component: () => import("@/views/UserWeb/Surveys/ViewOnlineReport.vue"),
            props: (route) => ({
              moduleId: Number(route.params.moduleId),
              siteId: Number(route.params.siteId),
              surveyId: Number(route.params.surveyId),
            }),
          },
          {
            path: "library",
            name: UserWebRouteName.Library,
            component: () => import("@/views/UserWeb/Library/ViewLibrary.vue"),
            props: (route) => ({ moduleId: Number(route.params.moduleId) }),
          },
          {
            path: "questionnaire",
            name: UserWebRouteName.Questionnaire,
            component: () => import("@/views/UserWeb/Questionnaire/ViewQuestionnaire.vue"),
            props: (route) => ({
              moduleId: Number(route.params.moduleId),
            }),
          },
          {
            path: "executive-summary",
            name: UserWebRouteName.ExecutiveSummary,
            component: () => import("@/views/UserWeb/ExecutiveSummary/ExecSummaryView.vue"),
            props: (route) => ({
              moduleId: Number(route.params.moduleId),
            }),
          },
          {
            path: "rec-tracking",
            redirect: { name: UserWebRouteName.RecTrackingOverview },
            name: UserWebRouteName.RecTracking,
            component: () => import("@/views/UserWeb/RecTracking/ViewRecTracking.vue"),
            props: (route) => ({
              moduleId: Number(route.params.moduleId),
            }),
            children: [
              {
                path: "overview",
                name: UserWebRouteName.RecTrackingOverview,
                component: () => Promise.resolve({ render: () => null }),
              },
              {
                path: "detailed",
                name: UserWebRouteName.RecTrackingDetailed,
                component: () => Promise.resolve({ render: () => null }),
              },
            ],
          },
          {
            path: "data-analysis-and-trends",
            redirect: { name: UserWebRouteName.DAATBlueTrend },
            name: UserWebRouteName.DAAT,
            component: () => import("@/views/UserWeb/DataAnalysisAndTrends/DAATView.vue"),
            props: (route) => ({
              moduleId: Number(route.params.moduleId),
            }),
            children: [
              {
                path: "blue-trend",
                name: UserWebRouteName.DAATBlueTrend,
                component: () => Promise.resolve({ render: () => null }),
              },
              {
                path: "loss-values",
                name: UserWebRouteName.DAATLossValues,
                component: () => Promise.resolve({ render: () => null }),
              },
              {
                path: "raw-data",
                name: UserWebRouteName.DAATRawData,
                component: () => Promise.resolve({ render: () => null }),
              },
            ],
          },
        ],
      },
    ],
  },
];
