import { unref } from "vue";

export const toPlainObject = (obj: unknown): unknown => {
  if (typeof obj === "function") {
    return undefined;
  }

  const unwrapped = unref(obj);

  if (Array.isArray(unwrapped)) {
    return unwrapped.map(toPlainObject);
  }

  if (unwrapped === null || unwrapped === undefined) {
    return unwrapped;
  }

  if (typeof unwrapped === "object") {
    return Object.fromEntries(
      Object.entries(unwrapped).map(([key, value]) => [key, toPlainObject(value)]),
    );
  }

  return unwrapped;
};

// Compare two objects and return the difference as record, with the key being the flattened path to the difference
// example: { "user.name": "string !== number" }
export const getDiff = (
  baseObject: unknown,
  modifiedObject: unknown,
  baseKey = "",
): Record<string, string> => {
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  const base = toPlainObject(baseObject) as any;
  const modified = toPlainObject(modifiedObject) as unknown;

  if (!base && !modified) {
    return {};
  }

  if (!base) return { [`${baseKey}base`]: typeof base };

  if (!modified) return { [`${baseKey}modified`]: typeof modified };

  const modifiedEntries = Object.entries(modified);
  let diff: Record<string, string> = {};

  for (const modifiedEntry of modifiedEntries) {
    const [key, value] = modifiedEntry;

    if (typeof key === "function") {
      continue;
    }

    if (typeof value !== typeof base[key]) {
      diff[`${baseKey}${key}`] = `${typeof base[key]} !== ${typeof value}`;
      continue;
    }

    if (typeof value === "object" || Array.isArray(value)) {
      const valueDiff = getDiff(base[key], value, `${baseKey}${key}.`);
      diff = { ...diff, ...valueDiff };
      continue;
    }

    if (value !== base[key]) {
      diff[`${baseKey}${key}`] = `${base[key]} !== ${value}`;
    }
  }

  return diff;
};

export const isModified = (baseObject: unknown, modifiedObject: unknown): boolean => {
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  const base = toPlainObject(baseObject) as any;
  const modified = toPlainObject(modifiedObject) as unknown;

  if (!base && !modified) {
    return false;
  }

  if (!base || !modified) {
    return true;
  }

  const modifiedEntries = Object.entries(modified);

  for (const modifiedEntry of modifiedEntries) {
    const [key, value] = modifiedEntry;

    if (typeof key === "function") {
      continue;
    }

    if (typeof value !== typeof base[key]) {
      return true;
    }

    if (typeof value === "object" || Array.isArray(value)) {
      const hasChange = isModified(base[key], value);

      if (hasChange) {
        return true;
      }
      continue;
    }

    if (value !== base[key]) {
      return true;
    }
  }

  return false;
};
